/**
 * Login reducer
 */

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from './constants';

export const initialState = {
  status: undefined,
  statusDetails: {},
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        status: 'loading',
        statusDetails: {},
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: 'success',
        statusDetails: {
          message: 'Login successful.',
        },
        user: action.response,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        status: 'error',
        statusDetails: action.error,
      };
    default:
      return state;
  }
}

export default loginReducer;
