/**
 * Login saga
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import qs from 'qs';
import {
  api,
  apiErrorHandler,
  apiSetAuthorizationToken,
} from '_platform/src/utils/request'; // _platform
import { formatToken, storeToken } from '_platform/src/utils/tokenUtils'; // _platform
import { isUrlAbsolute, removeProperty } from '_platform/src/utils/utilities'; // _platform
import history from '_platform/src/utils/history'; // _platform
import { LOGIN_REQUEST } from './constants';
import { /*loginSuccess,*/ loginError } from './actions';
import { setUser } from '_platform/src/containers/App/actions'; // _platform

/**
 * Request login
 */
export function* requestLogin(values) {
  const { username, password, webstore } = values.values;
  const { settingsApp } = values;
  const { loginPagePath, logoutPagePath } = settingsApp;

  if (!username || !password || !webstore) {
    yield put(
      loginError({
        title: 'Login error',
        message: 'Missing username, password or webstore',
      })
    );
    return; // Terminate the request
  }

  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.post(
        '/Authentication/v1/Rexel',
        JSON.stringify({ username, password, webstore })
      )
    );
    /*
    Expected response status codes:
    * Successful login:
      200, data: object with token details
    * Login Failed:
      400, message: "An error occurred trying to login user." (invalid user name) or "Login failed." (wrong password)
      Incorrect user or password.
    * 2FA error:
      400, message: "2-Factor authentication has not been implemented"
    * User is not allowed to login:
      403, message: "Not allowed."
      Internal backend auth functionality.
    * User is locked out:
      403, message: "Locked out."
      Too many failed attempts - temporary lockout.
    * User is not Authorised:
      403, message: "Unauthorised."
      The user has been prevented from logging in by an admin
    */

    // Make sure all Rexel users have 'forcePasswordChange' disabled.

    if (response.data && response.data.errorDescription) {
      yield put(loginError(response.data.errorDescription));
    } else {
      // Format user's token data
      const userData = formatToken(response.data);

      //yield put(loginSuccess(userData));

      // Get the query string
      // Use lowercaseKeys to make the object keys predictable
      const pageQueryString = qs.parse(
        history.location.search.replace(/returnUrl/gi, 'returnUrl'),
        {
          ignoreQueryPrefix: true,
        }
      );

      const defaultReturnPath =
        history.location.pathname !== loginPagePath &&
        history.location.pathname !== logoutPagePath &&
        (Object.keys(pageQueryString).length === 0 ||
          (pageQueryString.returnUrl &&
            Object.keys(pageQueryString).length > 1))
          ? history.location.pathname // Return the user to the same path they came from
          : '/';

      // Set the returnUrl from the query string, otherwise go to the home page
      const returnUrl =
        pageQueryString.returnUrl && !isUrlAbsolute(pageQueryString.returnUrl)
          ? pageQueryString.returnUrl // Note that the properties will be lowercase
          : defaultReturnPath;

      // Original queryString but sanitised
      const cleanQueryString = qs.stringify(pageQueryString);

      // Remove returnUrl from querystring and keep the remainder
      const trimmedQueryString = qs.stringify(
        removeProperty(pageQueryString, 'returnUrl')
      );

      // Set API Authorization token to be used on subsequent calls
      apiSetAuthorizationToken(userData.token);

      // Check if the user has a valid profile
      if (
        response.data.isProfileValid === true &&
        response.data.forcePasswordChange === false
      ) {
        // Store the authentication info
        storeToken(userData, settingsApp.loginUseSessionStorage);

        // Put authentication info into App state
        yield put(setUser(userData));

        // Redirect to home, or to returnUrl value. Pass on any remaining query string values
        yield call(history.push, {
          pathname: returnUrl,
          search: trimmedQueryString && `?${trimmedQueryString}`,
        });
      } else {
        // Save the current password in state as we're immediately asking the
        // user to reset it, and removing it from state straight after
        const oldPassword = response.data.forcePasswordChange
          ? { oldPassword: password }
          : null;

        // Put authentication info into App state as currentUserTemp until the profile is complete
        yield put(
          setUser(userData, {
            forcePasswordChange: response.data.forcePasswordChange,
            isProfileValid: response.data.isProfileValid,
            ...oldPassword,
          })
        );

        const { redirectOnIncompleteProfile } = settingsApp;

        // Check if we should redirect to another page if the profile is incomplete
        // Include any query strings that may be present
        if (
          redirectOnIncompleteProfile &&
          response.data.forcePasswordChange === false
        ) {
          // Remove returnUrl if it matches redirectOnIncompleteProfile path
          const redirectQS =
            pageQueryString.returnUrl === redirectOnIncompleteProfile
              ? trimmedQueryString
              : cleanQueryString;

          yield call(history.push, {
            pathname: redirectOnIncompleteProfile,
            search: redirectQS && `?${redirectQS}`,
          });
        }
      }
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put(
      loginError(
        error && error.message === 'Locked out.'
          ? { ...error, message: 'Too many failed attempts - locked out.' }
          : error
      )
    );
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Default saga managers the watcher lifecycle
 */
export default function* loginSaga() {
  // Specify the constant to watch and the saga generator function to call when one is triggered
  // It returns a task descriptor (just like a fork) so we can continue execution, and will be
  // automatically cancelled on unmount (unless the mode is set to DAEMON)
  // Specify as many yields (watchers) here as necessary
  yield takeLatest(LOGIN_REQUEST, requestLogin);
}
