/**
 * Login selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Container state
 */
const selectLoginState = state => state.login || initialState;

/**
 *  Fetch state
 */
const selectStatus = () =>
  createSelector(
    selectLoginState,
    localstate => ({
      status: localstate.status,
      statusDetails: localstate.statusDetails,
    })
  );

export { selectLoginState, selectStatus };
